import styled from "styled-components";

export const LogoContainer = styled.div`
 	display:flex;
	 width:100%;
	 padding-left:5%;
	 padding-right:5%;
	 
	justify-content:space-between;
	align-items: baseline;
	margin-top: 30px;
	margin-bottom: 40px;
	& .encontro{
		height:60%;  
	} 

	& .logo_tim{
		height:20%;  
	}

	@media (max-width: 768px){ 
		display:none;
	}
`

export const MainContainer = styled.div`
	padding:20px;
	display: flex;
	flex-direction: row;
	width: 100%;
	/* max-height:700px; */
	gap:5%;
	max-height: none;
	max-width: 1700px;
	padding-bottom:100px;

  @media (max-width: 1200px){ 
	max-width:100%;
	flex-direction: column;
	gap:15px;
  }
`

export const MessageContainer = styled.div`
	background-color:white;
	max-height:500px;
	overflow-y: scroll;
	border-radius:2px;
	/* mix-blend-mode:normal; */
	background-color:white;
	min-height:500px;
`
// export const Message = styled.div`
// 	border: 5px solid black;
// 	height:100px;
// 	width:100%;
// `

export const Label = styled.div`
  color:${props => props.theme.AccentBlue};
  padding-bottom:10px;
  font-size:18;
  font-weight:500;
  /* background-color:red; */
  
`


export const VideoContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const Chat = styled.div`
  width: 800px;
  min-height:660px;
  height:660px;
  position:relative;
  /* background-color:white; */
  
  
  @media (max-width: 1200px){ 
	width:100%;
	max-height:none;
	
  }
`

export const EncontroMobile = styled.div`
display:none;


 @media (max-width: 768px){ 
	display:flex;
	justify-content:center;
	height:auto;
	margin-top:50px;
	margin-bottom:50px;
	& img{
		width:70%;
	} 
 } 
`
export const LogoMobile = styled.div`
display:none;

 @media (max-width: 768px){ 
	display:flex;
	justify-content:center;
	height:auto;
	margin-top:10px;
	margin-bottom:80px;
	& img{
		width:70%;
	} 
 } 
`


export const IFrameContiner = styled.div`
	position:relative;
	border: 20px solid #022548;
	z-index: 2;
	border-radius:6px;
	& img {display:block;width:100%;height:auto;}
	& iframe{
		position:absolute;
		top:0;
		left:0;
		width:100%; 
		height:100%;
	}
`
