import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Encontro, Logo } from '../../assets/Images';
import { Background } from '../../components/Background';
import { Button } from '../../components/Button/Button';
import { ContainerBackground } from '../../components/Container/Container';
import { apiUrl } from '../../config';
import { useCepHandler } from '../../hooks/useCepHandler';
import { useTelefoneHandler } from '../../hooks/useTelefoneHandler';
import loginState, { getLoginState } from '../../store/slices/login';

import { BairroInput, CEPInput, CidadeInput, ComplementoInput, EmailInput, EncontroMobile, Form, FormContainer, FormLine, Label, LogoContainer, LogoMobile, LogradouroInput, NumeroInput, TelefoneInput, UFInput } from './styles';




function Login() {
	const history = useHistory();
	const dispatch = useDispatch();
	const login = useSelector(getLoginState);
	useEffect(() => { if (login.token == null) { history.push("/") }  if (login.realizouCadastro == true) { history.push("/chat") } }, [])
	const [erroCadastro, setErroCadastro] = useState('');
	const [logradouro, setLogradouro] = useState("");
	const [numero, setNumero] = useState("");
	const [complemento, setComplemento] = useState("");
	const [bairro, setBairro] = useState("");
	const [cidade, setCidade] = useState("");
	const [uf, setUf] = useState("");
	const [shareData, setShareData] = useState("1");
	const { formatedCep, setCep } = useCepHandler();
	const { formatedTelefone, setTelefone } = useTelefoneHandler();
	//const dispatch = useDispatch()

	const registrarAction = async () => {
		const cep = formatedCep.replace(/\D+/g, '');
		const telefone = formatedTelefone.replace(/\D+/g, '');
		const camposComProblemas = [];
		const ufs = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];
		if (cep.length !== 8) camposComProblemas.push("CEP");
		if (telefone.length !== 10 && telefone.length !== 11) camposComProblemas.push("Telefone");
		if (logradouro.length < 3) camposComProblemas.push("Logradouro");
		if (numero.length < 1) camposComProblemas.push("Número");
		if (bairro.length < 3) camposComProblemas.push("Bairro");
		if (cidade.length < 3) camposComProblemas.push("Cidade");
		if (!ufs.includes(uf.toUpperCase())) camposComProblemas.push("UF");
		const ufEnvio = uf.toUpperCase();

		if (camposComProblemas.length !== 0) {
			setErroCadastro(camposComProblemas.map(val => `*${val}`).join(', '));
			return;
		}

		const res = await axios.post(`${apiUrl}/custom/api/user/register_address_data.php`, {
			"address": logradouro,
			"number": numero,
			"complement": complemento,
			"district": bairro,
			"city": cidade,
			"state": ufEnvio,
			"zipcode": cep,
			"phone": telefone,
			"share_data":shareData,
		});

		if (res.status === 200) {
			const { register_data, token, name } = res.data.data;
			switch (res.data.status) {
				case 1:
					await dispatch(loginState.actions.realizaCadastro({ realizouCadastro: true }));
						history.push("/chat");
					return true;
					break;
				case -5:
					setErroCadastro("*Logradouro");
					break;
				case -6:
					setErroCadastro("*Número");
					break;
				case -7:
					setErroCadastro("*Bairro");
					break;
				case -8:
					setErroCadastro("*Cidade");
					break;
				case -9:
					setErroCadastro("*UF");
					break;
				case -10:
					setErroCadastro("*CEP");
					break;
				case -11:
					setErroCadastro("*Telefone");
					break;
				case -13:
					setErroCadastro("*Compartilhamento dos dados");
					break;

				case -1:
				case -2:
				case -3:
				case -4:
				default:
					await dispatch(loginState.actions.logout());
					history.push("/login")
					break;
			}
		} else {
			await dispatch(loginState.actions.logout());
			history.push("/login");
		}
	}
	if(login.realizouCadastro){return (<div/>)}
	return (
		<>

			<Background>
				<LogoContainer>
					<Encontro />
					<Logo />
				</LogoContainer>


				<EncontroMobile>
					<Encontro />
				</EncontroMobile>
				<FormContainer>
					<ContainerBackground />
					<Form style={{zIndex:3}}>
						<div>Seja bem-vindo, {login.nome}</div>
						<div>Favor informar os dados abaixo para ingressar ao evento</div>
						<FormLine>
							<div>
								<Label>Logradouro</Label>
								<LogradouroInput className={`${erroCadastro.includes("Logradouro") ? "erro" : ""}`} onChange={(e) => setLogradouro((e.target as HTMLInputElement).value)}></LogradouroInput>
							</div>
							<div>
								<Label>Número</Label>
								<NumeroInput className={`${erroCadastro.includes("Número") ? "erro" : ""}`} onChange={(e) => setNumero((e.target as HTMLInputElement).value)}></NumeroInput>
							</div>
							<div>
								<Label>Complemento</Label>
								<ComplementoInput className={`${erroCadastro.includes("Complemento") ? "erro" : ""}`} onChange={(e) => setComplemento((e.target as HTMLInputElement).value)}></ComplementoInput>
							</div>
						</FormLine>

						<FormLine>
							<div>
								<Label>Bairro</Label>
								<BairroInput className={`${erroCadastro.includes("Bairro") ? "erro" : ""}`} onChange={(e) => setBairro((e.target as HTMLInputElement).value)}></BairroInput>
							</div>
							<div>
								<Label>Cidade</Label>
								<CidadeInput className={`${erroCadastro.includes("Cidade") ? "erro" : ""}`} onChange={(e) => setCidade((e.target as HTMLInputElement).value)}></CidadeInput>
							</div>
							<div>
								<Label>UF</Label>
								<UFInput className={`${erroCadastro.includes("UF") ? "erro" : ""}`} maxLength={2} onChange={(e) => setUf((e.target as HTMLInputElement).value)}></UFInput>
							</div>
						</FormLine>

						<FormLine>
							<div>
								<Label>CEP</Label>
								<CEPInput className={`${erroCadastro.includes("CEP") ? "erro" : ""}`} value={formatedCep} type="text" onChange={setCep}></CEPInput>
							</div>
							<div>
								<Label>Telefone</Label>
								<TelefoneInput className={`${erroCadastro.includes("Telefone") ? "erro" : ""}`} value={formatedTelefone} onChange={setTelefone}></TelefoneInput>
							</div>
							<div>
								<Label>E-mail corporativo</Label>
								<EmailInput value={login.email || ''} disabled></EmailInput>
							</div>
						</FormLine>
						<FormLine style={{paddingTop:20,flexDirection:"column-reverse"}}>
							<div style={{minWidth:120,paddingTop:10}}>

								<input checked={shareData === "1"} onChange={() => setShareData("1")}  type="radio" id="male" name="algo" value={1}/>SIM
								<span style={{paddingRight:15}}></span>
								<input checked={shareData === "0"} onChange={() => setShareData("0")}  type="radio" id="female" name="algo" value={0}/>NÃO
							</div>
							<div>
								Autorizo o compartilhamento dos dados fornecidos neste formulário com os Parceiros da TIM na realização do Encontro Sales Consumer & SMB, com a finalidade de viabilizar o sorteio e a entrega dos prêmios do sorteio*
							</div>
						</FormLine>
						<FormLine>
							<small style={{paddingTop:20}}>
							*A TIM se preocupa com a privacidade e proteção dos dados pessoais de seus Colaboradores e, por isso, queremos ser transparentes com a forma como trataremos os dados pessoais obtidos em virtude do Evento. Durante o Evento, promoveremos uma série de sorteios interessantes, com o apoio de Parceiros bastante relevantes para a TIM. Assim, para permitir a sua participação nos sorteios e a entrega dos prêmios aos contemplados, a TIM precisará compartilhar os dados pessoais fornecidos no site de inscrição do evento com tais Parceiros. Se você tiver qualquer dúvida, fique à vontade para entrar em contato conosco através do e-mail {'<'}comunicacaofdvconsumerhq@timbrasil.com.br{'>'}.
							</small>
						</FormLine>

						<FormLine>
							<Button onClick={registrarAction} style={{ width: 260, marginTop: 40, cursor: "pointer" }}>Finalizar Cadastro</Button>

							{erroCadastro !== '' && <div style={{ marginTop: 40,width:300 }}>
								<div style={{ fontWeight: 700, fontSize: 16 }}>Atenção! Existe algum erro no campo:</div>
								<div style={{ fontWeight: 400, fontSize: 16 }}>{erroCadastro}</div>
							</div>}

						</FormLine>
					</Form>
				</FormContainer>
				<LogoMobile>
					<Logo />
				</LogoMobile>

			</Background>
		</>
	);
}

export default Login;
