import io from 'socket.io-client';
import { chatGUID, socketURL } from '../config';
import store from '../store'
import messageState from '../store/slices/messages';
import videoState from '../store/slices/video';
let socket: any = null;




export function startSocket(guid: any, hash:any) {

	if (socket) return;
	socket = io(socketURL, { upgrade: false, transports: ['websocket'] });

	socket.on('connect', function (data:any) {
		socket.emit('start', { guid, hash });
		console.log("connectado");
	});

	// renderiza todas mensagens recebidas (no início da conexão)
	socket.on('notification', function (messages: any) {

	});
	socket.on('general', function (message: any) {
		if(message.titulo === "chatStatus"){
			store.dispatch(videoState.actions.changeChat({
				"chat":!!parseInt(message.texto.chat,10),
			}))
		}
		else if(message.titulo === "remove_message"){
			store.dispatch(messageState.actions.removeMessage({
				"guid":message.texto.guid_message,
			}))
		}
		else if(message.texto.guid_message){
			store.dispatch(messageState.actions.addMessage({
				"guid":message.texto.guid_message,
				"guid_chat":chatGUID,
				"guid_author":message.texto.guid_author,
				"name_author":message.texto.name_author,
				"message":message.texto.message,
				"date":message.data
			}))
		}else{
			store.dispatch(videoState.actions.changeVideo({
				"src":message.texto,
			}))
		}
		
	});

	socket.on('reconnect_attempt', function () {

	});

}

export function stop() {
// 	socket. ('reconnect_attempt', function () {
// 		console.log("reconnect");
// 	});
}