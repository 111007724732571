import styled from "styled-components";

export const LogoContainer = styled.div`
  position: absolute;
  display:flex;
  flex-direction:column;
  /* gap:40px; */
  z-index:4;
  width:25%;
  /* height:100%; */
  left:7%;
  top:7%;

  & .encontro{
		height:auto;  
	} 

	& .logo_tim{
		width:50%;
		height:auto;  
	}

  @media (max-width: 768px){ 
	display:none;
  }
`

export const Form = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  padding-left: 74px;
  padding-right: 74px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px){ 
	/* mix-blend-mode:normal; */
	padding-left: 20px;
  	padding-right: 20px;
  }
`

export const Label = styled.div`
  margin-left: 10px;
  font-size: 18px;
  padding-bottom: 5px;
  margin-top: 20px;
  
  /* @media (max-width: 768px){ 
	mix-blend-mode:normal;
  } */
`

export const EncontroMobile = styled.div`
display:none;
z-index:4;
isolation:isolate;


 @media (max-width: 768px){ 
	display:flex;
	justify-content:center;
	height:auto;
	margin-top:50px;
	margin-bottom:50px;
	& img{
		width:70%;
	} 
 } 
`
export const LogoMobile = styled.div`
display:none;
z-index:4;
 @media (max-width: 768px){ 
	display:flex;
	justify-content:center;
	height:auto;
	margin-top:10px;
	margin-bottom:80px;
	& img{
		width:70%;
	} 
 } 
`

