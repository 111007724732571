import styled from "styled-components";
import { Container } from "../../components/Container/Container";
import { DefaultInput, FlatInput } from "../../components/Input/Input";

export const FormContainer = styled(Container)`
  
  width: 716px;
  @media (max-width: 768px){ 
	width:90%;
  }
`

export const LogradouroInput = styled(DefaultInput)`
  width: 260px;
  @media (max-width: 768px){ 
	width:100%;
  }
`

export const NumeroInput = styled(DefaultInput)`
  width: 90px;
  @media (max-width: 768px){ 
	width:100%;
  }
`

export const ComplementoInput = styled(DefaultInput)`
  width: 200px;
  @media (max-width: 768px){ 
	width:100%;
  }
`

export const BairroInput = styled(DefaultInput)`
  width: 260px;
  @media (max-width: 768px){ 
	width:100%;
  }
`

export const CidadeInput = styled(DefaultInput)`
  width: 200px;
  @media (max-width: 768px){ 
	width:100%;
  }
`

export const UFInput = styled(DefaultInput)`
  width: 90px;
  @media (max-width: 768px){ 
	width:100%;
  }
`

export const CEPInput = styled(DefaultInput)`
  width: 110px;
  @media (max-width: 768px){ 
	width:100%;
  }
`

export const TelefoneInput = styled(DefaultInput)`
  width: 130px;
  @media (max-width: 768px){ 
	width:100%;
  }
`

export const EmailInput = styled(FlatInput)`
  width: 310px;
  @media (max-width: 768px){ 
	width:100%;
  }
`


export const LogoContainer = styled.div`
	width: 100%;
	display:flex;
	justify-content:space-evenly;
	align-items: baseline;
	height:auto;
	margin-top:50px;
	& .encontro{
		height:60%;  
		width:auto;
	} 

	& .logo_tim{
		height:20%;  
		width:auto;
	}

	@media (max-width: 768px){ 
		display:none;
	}
`

export const Form = styled.div`
	isolation:isolate;
	z-index:4;
	width: 100%;
	height: 100%;
	max-height:none;
	padding-left: 54px;
	padding-right: 54px;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;

	@media (max-width: 768px){ 
		/* mix-blend-mode:normal; */
		padding-left: 20px;
		padding-right: 20px;
	}
`

export const FormLine = styled.div`
  display: flex;
  max-height:none;
  
  justify-content: space-between;
	flex-direction: row;

  @media (max-width: 768px){ 
  flex-direction: column;
	
  }
`


export const Label = styled.div`
  margin-left: 5px;
  font-size: 18px;
  padding-bottom: 5px;
  margin-top: 20px;
  
  /* @media (max-width: 768px){ 
	mix-blend-mode:normal;
  } */
`

export const EncontroMobile = styled.div`
display:none;


 @media (max-width: 768px){ 
	display:flex;
	justify-content:center;
	height:auto;
	margin-top:50px;
	margin-bottom:50px;
	& img{
		width:70%;
	} 
 } 
`
export const LogoMobile = styled.div`
display:none;

 @media (max-width: 768px){ 
	display:flex;
	justify-content:center;
	height:auto;
	margin-top:10px;
	margin-bottom:80px;
	& img{
		width:70%;
	} 
 } 
`

