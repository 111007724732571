import styled from "styled-components";

export const BackgroundImage = styled.div`
  position: relative;
  z-index:1;
  background-color:${props => props.theme.DarkBlueTim};
  background-image:url('/assets/man_background.jpg');
  background-position-x: right;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size:contain;

  width:100%;
  min-height:100%;
  /* height:100%; */
  display:flex;
  /* flex-wrap:wrap; */
  flex-grow:1;
  
 @media (max-width: 1200px){
	background-position: right -300px bottom 0px;
	background-size:cover; 
 }

 @media (max-width: 768px){ 

background-position: right -500px bottom -200px;
background-size:cover; 
  
}
`
export const Container2 = styled.div`
  isolation:isolate;

  width:100%;
  height:auto;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  flex-grow:1;
  
`

export const Container = styled.div`
  z-index:3;
  width:100%;
  height:auto;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  flex-grow:1;
  
`

export const Footer = styled.div`
position: fixed;
z-index:2;
background-image:url('/assets/logo_footer_login.png');
background-size:contain;
background-repeat: no-repeat;
background-position-y: bottom;
width:100%;
height:100%;
display:flex;
pointer-events: none;
mix-blend-mode: overlay;
`

