import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Tim', 'Roboto', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }
  *:focus{
    outline:none;
  }
  html{

	min-height:100%; 
	width:100%;
	
	@media (max-width: 768px){ 
		display:flex;
 	} 
	@media (min-width: 768px){ 
		height:100%;
 	} 
  }
  body{
	width:100%;
    overflow-x: hidden;
	@media (min-width: 768px){ 
		height:100%;
 	} 
  }
  #root{
	height:100%;
  }
  input::placeholder{
    color: ${props => props.theme.placeholder};
  }
`
