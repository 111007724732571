import styled from "styled-components";

export const Container = styled.div`
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top:200px;
}
  color:white;
  position: relative;
  margin:20px;

`

export const ContainerBackground = styled.div`
  background-color: ${props => props.theme.DarkBlueTim};
  height:100%;
  width:100%;
  opacity:0.8;
  border-radius: 6px;
  /* border: 0.1px solid ${props => props.theme.AccentBlue}; */
  box-shadow: 0px 40px 140px 0px ${props => props.theme.AccentBlue};
  /* mix-blend-mode:overlay; */
  position: absolute;
  pointer-events: none;

  @media (max-width: 768px){ 
	mix-blend-mode:normal;
  }
`