import styled from "styled-components";

export const Button = styled.button`
  height:40px;
  background-color:${props => props.theme.AccentBlue};
  color:${props => props.theme.White};
  border-radius: 3px;
  border:none;
  font-size:18px;

  @media (max-width: 768px){ 
	font-size:14px;
	height:37px;
  }

`