import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Encontro, Img16x9, Logo } from '../../assets/Images';
import { Background } from '../../components/Background';
import { Container, ContainerBackground } from '../../components/Container/Container';
import Message from '../../components/Message/Message';
import SendMessage from '../../components/Message/SendMessage';
import Video from '../../components/video/Video';
import { apiUrl, chatGUID, isProd } from '../../config';
import { getColor } from '../../services/colors';
import { startSocket } from '../../services/socket';
import { getLoginState } from '../../store/slices/login';
import messageState, { getMessageState } from '../../store/slices/messages';
import videoState, { getVideoState } from '../../store/slices/video';
import { EncontroMobile, MainContainer, Label, LogoContainer, LogoMobile, Chat, VideoContent, IFrameContiner, MessageContainer } from './styles';




function ChatView() {
	const enableChat = isProd? false:true;
	const history = useHistory();
	const dispatch = useDispatch();
	const chatRef = useRef(null);


	const login = useSelector(getLoginState);
	const video = useSelector(getVideoState);
	const messages = useSelector(getMessageState);
	// const [messages,setMessages]:any = useState([]);
	startSocket(login.guid, "tim");
	useEffect(() => {
		if (login.token == null) { history.push("/"); return; }
		if (login.realizouCadastro === false) { history.push("/cadastro"); return; }
		dispatch(messageState.actions.clean());
		loadPrevMessages();
		loadVideo();
		checkChatStatus();
	}, []);

	useEffect(() => {
		const objDiv = document.getElementById('chatContainer');
		if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;

	});

	const loadVideo = async() => {
		const res = await axios.post(`${apiUrl}/custom/api/plenary/list.php?`,{
			"token": login.token,
			"guid": login.guid,
		} ,{ withCredentials: true });
		if (res.status === 200 && res.data.status === 1) {
			console.log(res.data);
			if(res.data.data.length > 0)
				dispatch(videoState.actions.changeVideo({src:res.data.data}));
				
		}
	}

	const checkChatStatus = async() => {
		const res = await axios.post(`${apiUrl}/custom/api/chat/status.php?`,{
			"token": login.token,
			"guid": login.guid,
		} ,{ withCredentials: true });
		if (res.status === 200 && res.data.status === 1) {
			console.log("chat",!!parseInt(res.data.data.status,10));
			dispatch(videoState.actions.changeChat({chat:!!parseInt(res.data.data.status,10)}));
		}
	}

	const loadPrevMessages = async () => {
		const res = await axios.post(`${apiUrl}/custom/api/chat/history.php?`, {
			"chat_guid": chatGUID,
			"token": login.token,
			"guid": login.guid,
		}, { withCredentials: true });
		if (res.status === 200 && res.data.status === 1) {
			await dispatch(messageState.actions.prevMessages(res.data.data));
		}
	}

	const sendMessage = async (message: any) => {
		const res = await axios.post(`${apiUrl}/custom/api/chat/msg_add.php`, {
			"message": message,
			"chat_guid": chatGUID,
			"token": login.token,
			"guid": login.guid,
		}, { withCredentials: true });
	}
	console.log(video.chat)
	return (
		<>

			<Background>
				<LogoContainer>
					<Encontro />
					<Logo />
				</LogoContainer>

				<EncontroMobile>
					<Encontro />
				</EncontroMobile>
				<MainContainer>
					<VideoContent>
						<IFrameContiner>
							
							<Video src={video.src} />
							{/* <iframe style={{ zIndex: 3, height: "100%", width: "100%" }} title="algo" src="https://player.vimeo.com/video/38300442" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
						</IFrameContiner>
					</VideoContent>
					<Chat>
						<ContainerBackground />
						<div style={{zIndex:3, position:'absolute',width:'100%',padding:20}}>

							<Label>Chat</Label>
							<MessageContainer id={'chatContainer'} ref={chatRef}>
								{[...messages.messages].map((val: any) => (
									<Message key={val.guid} nome={val.name_author || 'Anonimo'} hora={val.date} message={val.message} color={getColor(val.guid_author?val.guid_author.toUpperCase():'')} />
								))}
							</MessageContainer>
							<SendMessage enableChat={video.chat} click={sendMessage} />
						</div>
					</Chat>
				</MainContainer>
				<LogoMobile>
					<Logo />
				</LogoMobile>

			</Background>
		</>
	);
}

export default ChatView;
