import { createGlobalStyle } from 'styled-components'

import timBoldEot from './TIMSansWeb-Bold.eot'
import timBoldWoff2 from './TIMSansWeb-Bold.woff2'
import timBoldWoff from './TIMSansWeb-Bold.woff'
import timBoldTTF from './TIMSans-Bold.ttf'
import timBoldSVG from './TIMSansWeb-Bold.svg'

import timHeavyEot from './TIMSansWeb-Heavy.eot'
import timHeavyWoff2 from './TIMSansWeb-Heavy.woff2'
import timHeavyWoff from './TIMSansWeb-Heavy.woff'
import timHeavyTTF from './TIMSans-Heavy.ttf'
import timHeavySVG from './TIMSansWeb-Heavy.svg'

import timLightEot from './TIMSansWeb-Light.eot'
import timLightWoff2 from './TIMSansWeb-Light.woff2'
import timLightWoff from './TIMSansWeb-Light.woff'
import timLightTTF from './TIMSans-Light.ttf'
import timLightSVG from './TIMSansWeb-Light.svg'

import timMediumEot from './TIMSansWeb-Medium.eot'
import timMediumWoff2 from './TIMSansWeb-Medium.woff2'
import timMediumWoff from './TIMSansWeb-Medium.woff'
import timMediumTTF from './TIMSans-Medium.ttf'
import timMediumSVG from './TIMSansWeb-Medium.svg'

import timRegularEot from './TIMSansWeb-Regular.eot'
import timRegularWoff2 from './TIMSansWeb-Regular.woff2'
import timRegularWoff from './TIMSansWeb-Regular.woff'
import timRegularTTF from './TIMSans-Regular.ttf'
import timRegularSVG from './TIMSansWeb-Regular.svg'

import timThinEot from './TIMSansWeb-Thin.eot'
import timThinWoff2 from './TIMSansWeb-Thin.woff2'
import timThinWoff from './TIMSansWeb-Thin.woff'
import timThinTTF from './TIMSans-Thin.ttf'
import timThinSVG from './TIMSansWeb-Thin.svg'


export default createGlobalStyle`

	@font-face {
	font-weight: 300;
	font-family: 'Tim';
	font-style: normal;
	src: url(${timThinEot}); /* IE9 Compat Modes */
	src: url(${timThinWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${timThinWoff}) format('woff'), /* Modern Browsers */
		url(${timThinTTF}) format('truetype'), /* Safari, Android, iOS */
		url(${timThinSVG}) format('svg'); /* Legacy iOS */
	}

	@font-face {
	font-weight: 400;
	font-family: 'Tim';
	font-style: normal;
	src: url(${timLightEot}); /* IE9 Compat Modes */
	src: url(${timLightWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${timLightWoff}) format('woff'), /* Modern Browsers */
		url(${timLightTTF}) format('truetype'), /* Safari, Android, iOS */
		url(${timLightSVG}) format('svg'); /* Legacy iOS */
	}

	@font-face {
	font-weight: 500;
	font-family: 'Tim';
	font-style: normal;
	src: url(${timRegularEot}); /* IE9 Compat Modes */
	src: url(${timRegularWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${timRegularWoff}) format('woff'), /* Modern Browsers */
		url(${timRegularTTF}) format('truetype'), /* Safari, Android, iOS */
		url(${timRegularSVG}) format('svg'); /* Legacy iOS */
	}

	@font-face {
	font-weight: 600;
	font-family: 'Tim';
	font-style: normal;
	src: url(${timMediumEot}); /* IE9 Compat Modes */
	src: url(${timMediumWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${timMediumWoff}) format('woff'), /* Modern Browsers */
		url(${timMediumTTF}) format('truetype'), /* Safari, Android, iOS */
		url(${timMediumSVG}) format('svg'); /* Legacy iOS */
	}

	@font-face {
	font-weight: 700;
	font-family: 'Tim';
	font-style: normal;
	src: url(${timBoldEot}); /* IE9 Compat Modes */
	src: url(${timBoldWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${timBoldWoff}) format('woff'), /* Modern Browsers */
		url(${timBoldTTF}) format('truetype'), /* Safari, Android, iOS */
		url(${timBoldSVG}) format('svg'); /* Legacy iOS */
	}

	@font-face {
	font-weight: 800;
	font-family: 'Tim';
	font-style: normal;
	src: url(${timHeavyEot}); /* IE9 Compat Modes */
	src: url(${timHeavyWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${timHeavyWoff}) format('woff'), /* Modern Browsers */
		url(${timHeavyTTF}) format('truetype'), /* Safari, Android, iOS */
		url(${timHeavySVG}) format('svg'); /* Legacy iOS */
	}
`