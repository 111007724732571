import { useState } from 'react'

export function useCepHandler () {
  const [formatedCep, setFormatedCep] = useState('')

  function setCaretPosition (ctrl:any, pos:any) {
    ctrl.focus()
    setTimeout(() => ctrl.setSelectionRange(pos, pos), 0)
  }

  function setCep (event:any) {
    const hasTraco = event.target.value.includes('-')
    let cep = event.target.value.replace(/\D+/g, '')
    const compare = formatedCep.replace(/\D+/g, '')
    if (!hasTraco && cep.length === compare.length) cep = cep.substr(0, 4) + cep.substr(5)
    const primeiraParte = cep.slice(0, 5)
    const segundaParte = cep.slice(5, 8)
    cep = primeiraParte + (segundaParte.length > 0 ? '-' : '') + segundaParte
    const carretpos = event.target.selectionStart + (!hasTraco && cep.includes('-') ? 1 : 0)
    setCaretPosition(event.target, carretpos)
    setFormatedCep(cep)
  }

  return {formatedCep, setCep}
}
