import React, { useEffect } from 'react';
import './App.css';
import RouterComponent from './router/router';
import { startLog } from './services/logSession';

function App() {
	useEffect(() => {
		startLog();
	}, [])
	return (
		<>
			<RouterComponent />
		</>
	);
}

export default App;
