import React, { useEffect, useState } from 'react';
import { Encontro, Logo } from '../../assets/Images';
import { BackgroundLogin } from '../../components/Background';
import { Button } from '../../components/Button/Button';
import { Container, ContainerBackground } from '../../components/Container/Container';
import { DefaultInput } from '../../components/Input/Input';
import { EncontroMobile, Form, Label, LogoContainer, LogoMobile } from './styles';
import {apiUrl, emailContato} from '../../config/index'
import axios from 'axios';
import loginState from '../../store/slices/login';
import {useDispatch} from 'react-redux';
import { useHistory } from "react-router-dom";





function Login() {
	const history = useHistory();
	const dispatch = useDispatch()
	const [emailNaoEncontrado, setEmailNaoEncontrado] = useState(false);
	const [load, setLoad] = useState(false);
	const [email, setEmail] = useState("");
	const [chave, setChave] = useState("");
	
	useEffect(() => { dispatch(loginState.actions.logout()); }, [])

	const enterAction = (e:any) => {
		if (e.key === 'Enter') {
			logarAction();
		}
	}

	const logarAction = async() => {
		setLoad(true);

		const res = await axios.post(`${apiUrl}/custom/api/login/email_key.php`,{
			"email": email,
			"key": chave,
			"keep_connected": 1
		},{withCredentials: true});

		if(res.status === 200){
			const {register_data,token,name,guid} = res.data.data;
			switch(res.data.status){
				case 1:
					await dispatch(loginState.actions.login({token,realizouCadastro:register_data,nome:name,email,guid}));
					setLoad(false);
					if(!register_data){
						history.push("/cadastro");
					}else{
						history.push("/chat");
					}
					return true;
					break;
				default:
					setEmailNaoEncontrado(true);
					return false;
					break;
			}
		}else{
			console.error("Alguma coisa aconteceu de errado com a conexão");
			return false;
		}
	}

	return (
		<>
			<LogoContainer>
				<Encontro />
				<div style={{height:20}}></div>
				<Logo />
			</LogoContainer>
			<BackgroundLogin>
				
			
				<EncontroMobile>
					<Encontro />
				</EncontroMobile>
				<Container style={{ height: 400, maxWidth: 400,display:"flex",flexDirection:"column",width:"90%" }}>
					<ContainerBackground />
					<Form style={{zIndex:3}}>
						<Label>E-mail corporativo</Label>
						<DefaultInput onKeyDown={enterAction} onChange={(e) => setEmail((e.target as HTMLInputElement).value)}></DefaultInput>
						<Label>Chave de acesso</Label>
						<DefaultInput onKeyDown={enterAction} onChange={(e) => setChave((e.target as HTMLInputElement).value)}></DefaultInput>
						<Button onClick={logarAction} style={{ marginTop: 20,cursor:"pointer" }}>Entrar</Button>
						
						{emailNaoEncontrado && <div style={{ marginTop: 40 }}>
							<div style={{ fontWeight: 700, fontSize: 16 }}>E-mail ou chave incorretos!</div>
							<div style={{ fontWeight: 400, fontSize: 16 }}>Favor entrar em contato com:</div>
							<div style={{overflowWrap:"break-word", fontWeight: 500, fontSize: 14 }}>{emailContato}</div>
						</div>}
					</Form>
				</Container>
				<LogoMobile>
					<Logo />
				</LogoMobile>
			
			</BackgroundLogin>
		</>
	);
}

export default Login;
