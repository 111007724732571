import axios from 'axios';
import { apiUrl } from '../config';
import store from '../store'
import { getLoginState } from '../store/slices/login';

let alreadyStarted = false;

export function startLog(){
	if(alreadyStarted) return;
	alreadyStarted = true;
	setInterval(() => {
		const login = getLoginState(store.getState());
		if (!login.token) return;
		const path = window.location.pathname;
		axios.get(`${apiUrl}/custom/api/user/log_session.php?url=${path}`, { withCredentials: true });
	},1000 * 60 * 5)

}