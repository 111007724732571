import { createSlice } from '@reduxjs/toolkit'
import { videoIMGDefault } from '../../config';

const videoState = createSlice({
  name: 'videoState',
  initialState: {
	src:videoIMGDefault,
	chat:false,
  },
  reducers: {
    changeVideo: (state,action:any) => {
		const {src} = action.payload;
		state.src=src && src != ""?src:videoIMGDefault;
	},
	changeChat: (state,action:any) => {
		const {chat} = action.payload;
		state.chat=chat;
	},
  }
})

export const getVideoState = (state:any) => state[videoState.name]
export const video = (state:any) => state[videoState.name].src

export default videoState
