import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { isProd } from '../config'
import loginState from './slices/login'
import messagesState from './slices/messages'
import videoState from './slices/video'


const persistedState = () => {
	if(localStorage.getItem('reduxState')){
		const json = JSON.parse(localStorage.getItem('reduxState') || "{}")
		delete json[messagesState.name]
		delete json[videoState.name]
		return json
	}else{
		return {};
	}
}

export const config:ConfigureStoreOptions = {
	reducer: {
		[loginState.name]: loginState.reducer,
		[messagesState.name]:messagesState.reducer,
		[videoState.name]:videoState.reducer,
	},
	// devTools: false,
	preloadedState: persistedState(),
	devTools:!isProd,
}




const store = configureStore(config)

store.subscribe(() => {
	localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})

export default store
