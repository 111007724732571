import styled from "styled-components";

export const DefaultInput = styled.input`
  border: 0.1px solid ${props => props.theme.BlueTIM};
  border-radius: 3px;
  height: 40px;
  border: none;
  color: ${props => props.theme.RoyalBlue};
  padding-left:15px;
  font-size:16px;
  font-weight:300;
  width:100%;
  
  &.erro{
	  color:white;
	  background-color:${props => props.theme.DarkBlueTim};
	  border: 1px solid ${props => props.theme.BlueTIM};
  }

  @media (max-width: 768px){ 
	font-size:14px;
	height:37px;
  }
`

export const FlatInput = styled.input`
  font-size:16px;
  height: 40px;

  background: ${props => props.theme.DarkBlueTim};
  border-radius: 3px;
  border: 1px solid ${props => props.theme.BlueTIM};
  color: ${props => props.theme.BlueTIM};

  &:disabled{
	border: 1px solid white;
	background: ${props => props.theme.DarkBlueTim};
	padding-left:15px;
	color: white;
  }

  @media (max-width: 768px){ 
	font-size:14px;
	height:37px;
  }
`
