import React from "react";
import { Provider } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import store from "../store";
import Cadastro from "../views/Cadastro/cadastro";
import Chat from "../views/Chat/chat";
import Login from "../views/Login/login";

function RouterComponent() {
	return (
		<>
			<Provider store={store}>
				<Router>
					<Switch>
						<Route path="/login">
							<Login />
						</Route>
						<Route path="/cadastro">
							<Cadastro />
						</Route>
						<Route path="/chat">
							<Chat />
						</Route>
						<Route path="/*">
							<Login />
						</Route>
					</Switch>
				</Router>
			</Provider>
		</>
	);
}

export default RouterComponent;
