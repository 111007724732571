import { createSlice } from '@reduxjs/toolkit'


const initialState:{messages:any[]} = {
	messages:[]
}

const messageState = createSlice({
  name: 'Messages',
  initialState,
  reducers: {
    addMessage: (state,action:any) => {
		state.messages=[...state.messages,action.payload];
	},
	removeMessage: (state,action:any) => {
		const {guid} = action.payload;
		state.messages = state.messages.filter(val => val.guid.toLowerCase() !== guid.toLowerCase());
	},
	clean: (state) => { state.messages = []},
	prevMessages:(state,action) =>{
		state.messages=[...action.payload,...state.messages];
		
	}
  }
})

export const getMessageState = (state:any) => state[messageState.name]

export default messageState
